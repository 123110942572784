.sidebar {
	@apply absolute top-14 -left-60 w-60 pt-4 flex flex-col overflow-x-hidden bg-uwgc-midblue text-uwgc-white z-50;
	height: calc(100vh - 3.5rem);
	transition: left cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
	.link {
		@apply text-lg font-bold p-[15px] cursor-pointer hover:backdrop-brightness-90;
		&.active {
			@apply text-uwgc-yellow;
		}
	}
	&.sidebar-open {
		@apply left-0;
	}
}
.backdrop {
	@apply fixed w-screen h-screen top-14 left-0 backdrop-brightness-50 z-50;
}
