.story-builder-sidebar {
	.accordion {
		.accordion-item:first-of-type,
		.accordion-item:last-of-type {
			@apply rounded-none;
		}
		.accordion-item {
			.accordion-header {
				@apply mb-0 border-b border-uwgc-darkGray;
				.accordion-button {
					@apply relative flex w-full items-center  rounded-none border-0 bg-uwgc-charcoal py-4 px-5 
					text-left text-base font-bold text-white shadow-none transition focus:outline-none;
					.ab-icon {
						transition: transform 0.2s ease-in-out;
					}
					&:not(.collapsed) {
						.ab-icon {
							transform: rotate(-180deg);
						}
					}
				}
				.accordion-button::after {
					display: none;
				}
			}
			.accordion-button {
				@apply hover:brightness-90;
			}
		}
	}
}
