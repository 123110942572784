@use "./form_elements.scss";

.blue-dashed {
	@apply border-4 border-dashed border-[#B0C5E4] p-5;
}
.blue-dashed-small {
	@apply border-2 border-dashed border-[#B0C5E4] p-2;
}
.blue-clickable {
	@apply text-uwgc-midblue cursor-pointer text-base font-bold;
}

.bg-checkered {
	--checkerSize: 10px;
	--checkerColor: rgb(236, 236, 236); /* edit me */
	--checkerAltColor: rgb(211, 211, 211);

	background-image: linear-gradient(45deg, var(--checkerColor) 25%, transparent 25%),
		linear-gradient(135deg, var(--checkerColor) 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, var(--checkerColor) 75%),
		linear-gradient(135deg, transparent 75%, var(--checkerColor) 75%);
	background-color: var(--checkerAltColor);

	background-size: calc(2 * var(--checkerSize)) calc(2 * var(--checkerSize));

	background-position: 0 0, var(--checkerSize) 0, var(--checkerSize) calc(-1 * var(--checkerSize)),
		0px var(--checkerSize);

	/* for fun */
	transition-property: background-color, background-position, background-size;
	transition-duration: 1s;
}
