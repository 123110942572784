$themeColor: theme("colors.uwgc.midblue");

.linear-activity {
	overflow: hidden;
	width: 100%;
	height: 4px;
	background-color: $themeColor;
	@apply bg-opacity-25;
}

.indeterminate {
	position: relative;
	width: 100%;
	height: 100%;
}

.indeterminate:before {
	content: "";
	position: absolute;
	height: 100%;
	background-color: $themeColor;
	animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
	content: "";
	position: absolute;
	height: 100%;
	background-color: $themeColor;
	animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
	0% {
		left: -100%;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 10%;
	}
}

@keyframes indeterminate_second {
	0% {
		left: -150%;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 10%;
	}
}

.lds-ring {
	display: inline-flex;
	position: relative;
	width: 100%;
	height: 80px;
	justify-content: center;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid $themeColor;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $themeColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
