//get font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

//define theme variables
$blue: #252a69;
$orange: #ff5700;
$midblue: #427abd;
$yellow: #ffd51a;
$lightGray: #f5f5f5;
$slate: #b0c5e4;
$white: #ffffff;
$charcoal: #4b4f58;
$green: #03c545;
$midGrey: #d2d2d2;
$red: #ff0000;

.story,
.container-preview,
.text-editor [role="textbox"] {
	font-family: "Open Sans", sans-serif;
	background-color: $charcoal;
	.h1 {
		font-size: calc(var(--font-size) * 3.25);
	}
	.highlight1 {
		font-weight: 800;
		font-size: calc(var(--font-size) * 3.25);
		line-height: calc(var(--font-size) * 4.4259);
	}

	.heading1 {
		font-weight: 600;
		font-size: calc(var(--font-size) * 3.25);
		line-height: calc(var(--font-size) * 4.4259);
	}
	.heading3 {
		font-weight: 600;
		font-size: calc(var(--font-size) * 2.75);
		line-height: calc(var(--font-size) * 3.75);
	}
	.h2 {
		font-size: calc(var(--font-size) * 3);
	}
	.h3 {
		font-size: calc(var(--font-size) * 2.75);
	}
	.h4 {
		font-size: calc(var(--font-size) * 2.5);
	}
	.h5 {
		font-size: calc(var(--font-size) * 2.25);
	}
	.h6 {
		font-size: calc(var(--font-size) * 2);
	}
	.h7 {
		font-size: calc(var(--font-size) * 1.5);
	}

	.h3-2 {
		font-size: calc(var(--font-size) * 3.25);
	}
	.higlight {
		color: $yellow;
	}
	.paragraphs {
		font-size: calc(var(--font-size) * 2);
	}
	.small {
		font-size: calc(var(--font-size) * 1.5);
	}
	.title {
		font-size: calc(var(--font-size) * 8);
		font-weight: 800;
		color: $white;
	}
	.blue {
		color: $blue;
	}
	.orange {
		color: $orange;
	}
	.midBlue {
		color: $midblue;
	}
	.yellow {
		color: $yellow;
	}
	.lightGray {
		color: $lightGray;
	}
	.slate {
		color: $slate;
	}
	.white {
		color: $white;
	}
	.charcoal {
		color: $charcoal;
	}
	.green {
		color: $green;
	}
	.midGrey {
		color: $midGrey;
	}
	.red {
		color: $red;
	}

	.check {
		display: inline-block;
		transform: rotate(45deg);
		height: calc(var(--font-size) * 2);
		width: calc(var(--font-size) * 1);
		border-bottom: calc(var(--font-size) * 0.3) solid $orange;
		border-right: calc(var(--font-size) * 0.3) solid $orange;
		margin: calc(var(--font-size) * 0.3) calc(var(--font-size) * 2) 0 calc(var(--font-size) * 2);
		flex-shrink: 0;
		&.yellow {
			border-bottom: calc(var(--font-size) * 0.3) solid $yellow;
			border-right: calc(var(--font-size) * 0.3) solid $yellow;
		}
		&.small {
			height: calc(var(--font-size) * 1.3);
			width: calc(var(--font-size) * 0.75);
			margin: calc(var(--font-size) * 0.3) calc(var(--font-size) * 1) 0 calc(var(--font-size) * 1);
		}
	}

	.legend {
		width: calc(var(--font-size) * 1.5);
		height: calc(var(--font-size) * 0.5);
		display: inline-block;
		vertical-align: middle;
		margin-bottom: calc(var(--font-size) * 0.25);
		&.blue {
			background: $blue;
		}
		&.orange {
			background: $orange;
		}
		&.midBlue {
			background: $midblue;
		}
		&.yellow {
			background: $yellow;
		}
		&.lightGray {
			background: $lightGray;
		}
		&.slate {
			background: $slate;
		}
		&.charcoal {
			background: $charcoal;
		}
		&.green {
			background: $green;
		}
	}

	//charts
	.pie-chart {
		.chart-ring {
			width: calc(var(--font-size) * 8.2);
			height: calc(var(--font-size) * 8.2);
		}
		.value {
			top: calc(var(--font-size) * 2.3);
			font-size: calc(var(--font-size) * 2.2);
		}
	}

	// @TODO temp styles, should come from DB
	// .section {
	// 	.frame {
	// 		color: $white;
	// 		@extend .frame-blue;
	// 	}
	// }
	.donor-text {
		font-size: calc(var(--font-size) * 2.75);
		font-weight: 600;
	}
	.donor-heading {
		font-size: calc(var(--font-size) * 3);
		font-weight: 600;
	}
}

// .my-section {
// 	@extend .section;
// }

.frame-default {
	background: linear-gradient(90deg, #333333 0%, #4e4e4e 33%, #4e4e4e 66%, #333333 100%);
	color: white;
}

//background gradients
.dark-to-blue {
	background: linear-gradient(90deg, #1a1e4a 0%, #252a69 100%);
}
.blue-to-dark {
	background: linear-gradient(90deg, #252a69 0%, #1a1e4a 100%);
}
.frame-blue {
	background: linear-gradient(90deg, #1a1e4a 0%, #252a69 33%, #252a69 66%, #1a1e4a 100%);
	color: white;
}

.dark-to-orange {
	background: linear-gradient(90deg, #ff2400 -1.59%, #ff5700 100%);
}
.orange-to-dark {
	background: linear-gradient(90deg, #ff5700 0%, #ff2400 100%);
}
.frame-orange {
	background: linear-gradient(90deg, #ff2400 0%, #ff5700 33%, #ff5700 66%, #ff2400 100%);
	color: white;
}

.dark-to-gray {
	background: linear-gradient(90deg, #dedede 0%, #f5f5f5 100%);
}
.gray-to-dark {
	background: linear-gradient(90deg, #f5f5f5 0%, #dedede 100%);
}
.frame-gray {
	background: linear-gradient(90deg, #dedede 0%, #f5f5f5 33%, #f5f5f5 66%, #dedede 100%);
	color: $charcoal;
}

.dark-to-midBlue {
	background: linear-gradient(90deg, #3058ab 0%, #427abd 100%);
}
.midBlue-to-dark {
	background: linear-gradient(90deg, #427abd 0%, #3058ab 100%);
}
.frame-midBlue {
	background: linear-gradient(90deg, #3058ab 0%, #427abd 33%, #427abd 66%, #3058ab 100%);
	color: white;
}

.dark-to-yellow {
	background: linear-gradient(90deg, #ffc00d 0%, #ffd51a 100%);
}
.yellow-to-dark {
	background: linear-gradient(90deg, #ffd51a 0%, #ffc00d 100%);
}
.frame-yellow {
	background: linear-gradient(90deg, #ffc00d 0%, #ffd51a 33%, #ffd51a 66%, #ffc00d 100%);
	color: $charcoal;
}

.screen-0 {
	&.frame-blue {
		@apply dark-to-blue;
	}
	&.frame-orange {
		@apply dark-to-orange;
	}
	&.frame-gray {
		@apply dark-to-gray;
	}
	&.frame-midBlue {
		@apply dark-to-midBlue;
	}
	&.frame-yellow {
		@apply dark-to-yellow;
	}
}

.screen-1 {
	&.frame-blue {
		@apply bg-uwgc-blue;
	}
	&.frame-orange {
		@apply bg-uwgc-orange;
	}
	&.frame-gray {
		@apply bg-uwgc-lightGray;
	}
	&.frame-midBlue {
		@apply bg-uwgc-midblue;
	}
	&.frame-yellow {
		@apply bg-uwgc-yellow;
	}
}

.screen-2 {
	&.frame-blue {
		@apply blue-to-dark;
	}
	&.frame-orange {
		@apply orange-to-dark;
	}
	&.frame-gray {
		@apply gray-to-dark;
	}
	&.frame-midBlue {
		@apply midBlue-to-dark;
	}
	&.frame-yellow {
		@apply yellow-to-dark;
	}
}
