.input {
	@apply flex h-11 w-full flex-row items-center rounded-[5px] border border-[#cccccc] bg-white p-4;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.input:invalid {
	border-color: #ff0000;
}
