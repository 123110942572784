.asset-library {
	// display: grid;
	// grid-template-columns: 1fr 1fr 1fr 1fr;
	// grid-template-rows: max-content;
	// grid-template-areas:
	// 	"sidebar sidebar sidebar upload"
	// 	"content content content content";
	.al-sidebar {
		// grid-area: sidebar;
		@apply flex items-stretch gap-4 text-xl font-bold text-uwgc-charcoal;
		.library-btn {
			@apply flex items-center gap-4 rounded bg-uwgc-midGrey p-4;
			&.active {
				@apply bg-uwgc-slate text-uwgc-blue;
			}
		}
	}
	.uaf-input {
		grid-area: upload;
		justify-self: flex-end;
		align-self: center;
		// margin: 1rem;
	}
	.af-content {
		grid-area: content;
	}
}
