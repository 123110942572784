.layer-tabs {
	@apply flex px-4 gap-4 overflow-hidden;
	.layer-tab {
		@apply bg-uwgc-midGrey text-uwgc-charcoal px-4 py-2 font-bold flex items-center gap-2 cursor-pointer;
		border-radius: 15px 50px 0px 0px;
		&.selected {
			@apply bg-uwgc-slate text-uwgc-blue;
		}
	}
}
