.map {
	width: 100%;
	.grid {
		height: 100%;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-flow: column;
		.container {
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			justify-content: center;
			align-items: center;
			max-width: unset;
			&.screen-0 {
				grid-column: 1/2;
			}
			&.screen-1 {
				grid-column: 2/3;
			}
			&.screen-2 {
				grid-column: 3/4;
			}
			&.screen-0-1 {
				grid-column: 1/3;
			}
			&.screen-1-2 {
				grid-column: 2/4;
			}
			&.screen-0-2,
			&.screen-0-1-2 {
				grid-column: 1/4;
			}
		}
	}
}
