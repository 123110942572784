.toggle-switch {
	@apply h-0 w-0;
	visibility: hidden;
}

.toggle-switch-label {
	@apply relative flex h-[45px] w-[90px] cursor-pointer items-center justify-between rounded-[100px] bg-slate-500;
	transition: background-color 0.2s;
}

.toggle-switch-label .toggle-switch-button {
	content: "";
	@apply absolute top-[2px] left-[2px] h-[40px] w-[40px] rounded-[45px] bg-uwgc-white;
	transition: 0.2s;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.toggle-switch:checked + .toggle-switch-label .toggle-switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.toggle-switch-label:active .toggle-switch-button {
	width: 60px;
}
span.on-text {
	@apply pl-[17px] text-base font-normal leading-[22px] text-uwgc-white;
}
span.off-text {
	@apply pl-[53px] text-base font-normal leading-[22px] text-uwgc-white;
}
