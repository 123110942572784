// .input-range {
// 	@apply h-3 w-full max-w-full appearance-none border-0 p-0 focus:shadow-none focus:outline-none focus:ring-0;
// }
// .input-range::-webkit-input-range {
// 	@apply h-5 w-5 cursor-pointer appearance-none rounded-full border border-solid border-uwgc-charcoal bg-uwgc-yellow;
// }

// .input-range::-moz-range-thumb {
// 	@apply h-3 w-3 cursor-pointer appearance-none rounded-full bg-uwgc-yellow;
// }
$background: none;

.input-range {
	background: none;
	width: 100%;
}
// used slider css generator
// https://www.cssportal.com/style-input-range/
input[type="range"] {
	height: 12px;
	border-radius: 20px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}
input[type="range"]:focus {
	outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 0px 0px 0px #000000;
	background: $background;
	border-radius: 35px;
	border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
	box-shadow: 0px 0px 0px #000000;
	border: 0px solid #2497e3;
	height: 30px;
	width: 30px;
	border-radius: 25px;
	background: #5fc15d;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -11px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
	background: $background;
}
input[type="range"]::-moz-range-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 0px 0px 0px #000000;
	background: $background;
	border-radius: 35px;
	border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
	box-shadow: 0px 0px 0px #000000;
	height: 30px;
	width: 30px;
	border-radius: 25px;
	background: #5fc15d;
	cursor: pointer;
}
input[type="range"]::-ms-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
}
input[type="range"]::-ms-fill-lower {
	background: $background;
	border: 0px solid #000000;
	border-radius: 70px;
	box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
	background: $background;
	border: 0px solid #000000;
	border-radius: 70px;
	box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
	margin-top: 1px;
	box-shadow: 0px 0px 0px #000000;
	height: 30px;
	width: 30px;
	border-radius: 25px;
	background: #5fc15d;
	cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
	background: $background;
}
input[type="range"]:focus::-ms-fill-upper {
	background: $background;
}
