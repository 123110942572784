@use "./components/display_status.scss";
@use "./components/header.scss";
@use "./components/tabs.scss";
@use "./generic.scss";

body {
	@apply bg-uwgc-lightGray;
}

.card {
	@apply flex flex-col;
	background: #ffffff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	padding: 50px;
}

.section-header {
	@apply flex items-center w-full h-12 text-uwgc-white font-bold text-xl bg-uwgc-charcoal p-3;
}
