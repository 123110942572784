table {
	text-align: left;
}
table {
	@apply min-w-full divide-y divide-gray-200 text-base;
}

thead {
	@apply bg-uwgc-charcoal text-uwgc-white font-bold;
}

tbody {
	@apply bg-white divide-y divide-gray-200;
}
td {
	@apply px-6 py-2 whitespace-nowrap text-gray-500;
}
th {
	@apply px-6 py-3 text-left font-medium tracking-wider text-xl font-bold;
}
