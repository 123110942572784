.story-display {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
}

.story-debug {
	position: absolute;
	top: 0;
	color: #00ff00;
	background-color: black;
	font-size: 18px;
}

.story-control {
	top: 0;
	color: #00ff00;
	// background-color: black;
	font-size: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	margin-bottom: 80px;
	.section-buttons {
		@apply flex flex-wrap justify-center;
		margin-top: 20px;
		.b-section {
			@apply bg-uwgc-midblue text-white p-2 rounded-sm;
			width: 150px;
			&.active {
				@apply bg-uwgc-yellow text-uwgc-charcoal;
			}
		}
		:disabled {
			filter: brightness(50%);
		}
	}
	.play-button {
		@apply bg-green-500 font-bold text-white p-2 flex items-center justify-center rounded-md gap-4;
		width: 150px;
	}
	.mute-button {
		@apply bg-uwgc-midblue font-bold text-white p-2 flex items-center justify-center rounded-md gap-4;
		width: 150px;
	}
	.stop-button {
		@apply bg-uwgc-orange font-bold text-white p-2 flex items-center justify-center rounded-md gap-4;
		width: 150px;
	}
}

.story-header {
	display: flex;
	justify-content: space-between;
	margin: 20px;
	margin-bottom: 40px;
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: "";
		background: transparent;
		color: white;

		font-size: 24px;
		padding: 10px;
		&:disabled {
			opacity: 1;
			color: white;
		}
	}
}

@media (min-aspect-ratio: 48/18) {
	.story-header,
	.story-control {
		display: none;
	}
}
