.maps {
	.map-settings {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		gap: 8px;
	}

	.map {
		width: 533.33vh;
		height: 100vh;
	}
}

@media (max-aspect-ratio: 48/9) {
	.maps {
		.map {
			width: 100vw;
			height: 18.75vw;
		}
	}
}
