.text-editor {
	@apply text-uwgc-charcoal;
	.toolbar {
		@apply flex flex-wrap items-stretch gap-4 rounded-t bg-uwgc-white p-2 text-uwgc-charcoal;
		.select-wrapper {
			@apply relative flex items-center;
			.select {
				background: unset;
				height: unset;
				@apply py-1 pl-2 pr-8;
			}
			.icon {
				@apply pointer-events-none absolute right-2 h-5 w-5;
			}
		}
		.toolbar-button {
			@apply flex h-8 items-center rounded  border border-uwgc-midGrey px-1;
			&.btnActive {
				@apply bg-uwgc-slate;
			}
		}
		.tooltip-alt {
			.toolbar-button {
				@apply h-full;
			}
		}
		.toolbar-grp {
			@apply flex flex-wrap gap-2;
		}
	}
	[role="textbox"] {
		@apply h-24 rounded-b border-t border-uwgc-midGrey bg-slate-400;
	}
}

.modal {
	.container-component-size-1 {
		.text-editor {
			.toolbar {
				width: 355.55px;
			}
			[role="textbox"] {
				height: 200px;
				aspect-ratio: 16/9;
				overflow: hidden;
				--font-size: 9.244px;
				@apply bg-slate-400;
			}
		}
	}
	.container-component-size-2 {
		.text-editor {
			.toolbar {
				width: 711.1px;
			}
			[role="textbox"] {
				height: 200px;
				aspect-ratio: 32/9;
				overflow: hidden;
				--font-size: 9.244px;
				// --font-size: 18.488px;
				@apply bg-slate-400;
			}
		}
	}
	.container-component-size-3 {
		.text-editor {
			.toolbar {
				width: 1066.65px;
			}
			[role="textbox"] {
				height: 200px;
				aspect-ratio: 48/9;
				overflow: hidden;
				--font-size: 9.244px;
				// --font-size: 27.73px;
				@apply bg-slate-400;
			}
		}
	}
}
