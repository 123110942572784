.story-builder {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 225px auto;
	grid-template-rows: 6rem auto;
	grid-template-areas:
		"sidebar header"
		"sidebar content";
	.story-builder-header {
		grid-area: header;
		@apply flex items-center justify-between px-4 py-5;
	}
	.story-builder-sidebar {
		grid-area: sidebar;
		overflow-y: auto;

		@apply bg-uwgc-darkGray;
	}
	.story-builder-content {
		grid-area: content;
		.story-detail {
			@apply p-12;
		}
	}

	.container-details {
		grid-row: 1;
		&.screen-0 {
			grid-column: 1/2;
		}
		&.screen-1 {
			grid-column: 2/3;
		}
		&.screen-2 {
			grid-column: 3/4;
		}
		&.screen-0-1 {
			grid-column: 1/3;
		}
		&.screen-1-2 {
			grid-column: 2/4;
		}
		&.screen-0-2,
		&.screen-0-1-2 {
			grid-column: 1/4;
		}

		&.screen-0,
		&.screen-1,
		&.screen-2 {
			.container-preview {
				aspect-ratio: 16/9;
			}
		}
		&.screen-0-1,
		&.screen-1-2,
		&.screen-0-2 {
			.container-preview {
				aspect-ratio: 32/9;
			}
		}
		&.screen-0-1-2 {
			.container-preview {
				aspect-ratio: 48/9;
			}
		}

		.container-preview {
			color: white;
			background-color: unset;
			// background-blend-mode: color;
		}

		.container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			justify-content: center;
			align-items: center;
			max-width: unset;
		}
	}

	.layers-overlay {
		position: relative;
		&.layer-all {
			width: 100%;
			aspect-ratio: 48/9;
			.layer {
				width: 100%;
				position: absolute;
				.container-components {
					display: none;
				}
			}
		}

		// @TODO TEMP STYLE
		.layer-background {
			width: 100%;
			position: absolute;
			top: 2px;
			aspect-ratio: 48/9;
		}

		.container-details {
			.container-preview {
				box-sizing: border-box;
				border-right: 1px dashed rgb(126, 126, 126);
			}
			&:last-child {
				.container-preview {
					border: 0;
				}
			}
		}
	}
	.frame-yellow-theme,
	.frame-gray-theme {
		.container-preview {
			@apply text-uwgc-charcoal;
		}
	}
}
