html,
body {
	--font-size: 4.63vh;
	// font-size: 4.63vh; //100px
	// background: black;
}

.main {
	// display: flex;
	// width: 533.33vh;
	// height: 100vh;
	background-color: #363636;
	opacity: 1;
	background-image: linear-gradient(90deg, #1a1a1a 0%, #363636 33%, #363636 66%, #1a1a1a 100%);
	background-position: 0 0, 0 0, 32px 56px, 32px 56px, 0 0, 32px 56px;
	overflow: hidden;
}

.main-story,
.map,
.splash {
	display: flex;
	width: 533.33vh;
	height: 100vh;
}

.splash {
	color: white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
}

.story {
	flex: 1;
	.section {
		height: 100%;
		.frame {
			position: relative;
			height: 100%;
			width: 100%;
			.layer {
				position: absolute;
				height: 100%;
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-auto-flow: column;
				.container {
					display: flex;
					flex-wrap: wrap;
					overflow: hidden;
					justify-content: center;
					align-items: center;
					max-width: unset;
					&.screen-0 {
						grid-column: 1/2;
					}
					&.screen-1 {
						grid-column: 2/3;
					}
					&.screen-2 {
						grid-column: 3/4;
					}
					&.screen-0-1 {
						grid-column: 1/3;
					}
					&.screen-1-2 {
						grid-column: 2/4;
					}
					&.screen-0-2,
					&.screen-0-1-2 {
						grid-column: 1/4;
					}
				}
			}
		}
	}
}

@media (max-aspect-ratio: 48/9) {
	html,
	body {
		--font-size: 0.87vw;
		// font-size: 0.87vw; //100px scale from 4k tv
	}
	// .main {
	// 	width: 100vw;
	// 	height: 18.75vw;
	// }
	.main-story,
	.map,
	.splash {
		width: 100vw;
		height: 18.75vw;
	}
}
