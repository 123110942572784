.modal {
	@apply fixed top-0 left-0 z-40 flex h-full w-full items-center justify-center;
	.card {
		@apply mx-10 flex max-w-full flex-col overflow-y-auto rounded-md bg-gray-700 p-0 text-white;
		.card-header {
			@apply m-4 flex flex-row justify-between;
			.close {
				@apply mt-1 mr-1 w-max cursor-pointer self-end text-black opacity-60;
			}
			.modal-title {
				@apply flex self-center text-xl font-bold;
			}
		}
		.modal-content {
			@apply flex flex-col overflow-y-auto px-0;
		}
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: -1;
	}
	input,
	textarea,
	select {
		@apply text-uwgc-charcoal;
	}
}
@media screen and (min-width: 820px) {
	.modal {
		.card {
			max-height: 90%;
		}
		.card .modal-content {
			@apply px-7 pb-7;
		}
	}
}
