.button {
	@apply flex justify-center items-center px-4 gap-4 rounded-md text-uwgc-white font-bold text-center uppercase h-11;
}
.button.orange {
	@apply bg-uwgc-orange;
}
.button.blue {
	@apply bg-uwgc-midblue;
}
.button.yellow {
	@apply bg-uwgc-yellow;
}
.button.green {
	@apply bg-uwgc-green;
}
.button.charcoal {
	@apply bg-uwgc-charcoal;
}
.button.red {
	@apply bg-uwgc-red;
}

button:disabled,
button[disabled] {
	background-color: rgb(203, 213, 225) !important;
}
