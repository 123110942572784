.private-content {
	height: calc(100vh - 3.5rem);
	.builder {
		height: 100%;
		display: grid;
		grid-template-rows: 3rem calc(100% - 3rem);
		overflow: hidden;
		.story-builder-content {
			overflow: auto;
		}
	}
	.control {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}
